import React from 'react';
import { DataStore, initialDataStore, } from 'src/Store/dataStore';
import { User } from 'src/Types/types';
import { sessionService } from 'src/Services/Session';

type Action =
  | {kind: "USER_LOGIN_REQUEST", payload: boolean}
  | {kind: "USER_LOGIN_SUCCESS", payload: User}
  | {kind: "USER_LOGIN_FAILURE", payload: boolean}
	| {kind: "USER_LOGOUT", payload: boolean }

  export type DataStoreDistpatch = React.Dispatch<Action>;

  export const DataStoreReducer = (prevState: DataStore, action: Action): DataStore => {
    switch( action.kind ){
      case "USER_LOGIN_REQUEST":
        return {
          ...prevState,
          passedAuth: action.payload
        };

      case "USER_LOGIN_SUCCESS":
        const successData = {
          ...prevState,
          user: action.payload,
          passedAuth: true
        };

				sessionService.setSession( successData );
				console.log( successData );
				return successData;
      
      case "USER_LOGIN_FAILURE":
        const failData = {
          ...prevState,
          passedAuth: false
        };

				sessionService.setSession( failData );
				return failData;
			
			case "USER_LOGOUT":
				const logoutData = {
					user:initialDataStore.user,
					passedAuth: false
				};

				sessionService.setSession( logoutData );
				return logoutData;

      default:
        return initialDataStore;
    }

  }