import {User} from 'src/Types/types';

export type DataStore = {
  user: User,
  passedAuth: boolean
}

export const initialDataStore: DataStore = {
  user: {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    created: "",
    last_login: "",
    last_ip: "",
    last_user_agent: "",
    modified: null,
    role_id: 0,
    role_name: ""
  },
  passedAuth: false
}