import React from 'react';

import { DataStoreReducer } from 'src/Store/dataStoreReducer';
import { initialDataStore } from 'src/Store/dataStore';
import { DataStoreContext } from 'src/Store/dataStoreContext';
import { sessionService } from 'src/Services/Session';
import Orders from 'src/Components/Orders/Orders';

import './App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const App = () => {
  const [dataStore, dataStoreDispatch] = React.useReducer(DataStoreReducer, sessionService.getSession() );

  return(
    <DataStoreContext.Provider value={{dataStoreDispatch, dataStore}}>
      <Orders />
    </DataStoreContext.Provider>
  )
}

export default App;
