import React,{useContext} from "react";
import { DataStore } from "./dataStore";
import { DataStoreDistpatch } from "./dataStoreReducer";

export type DataStoreContextType ={
  dataStore: DataStore,
  dataStoreDispatch: DataStoreDistpatch
}

export const DataStoreContext = React.createContext( (null as unknown) as DataStoreContextType );
export const useDataStoreContext = () => useContext( DataStoreContext );