import * as Cookies from 'js-cookie';
import {DataStore, initialDataStore} from 'src/Store/dataStore';

const createSessionService = () => {
	const setSession = ( session: DataStore ) => {
		Cookies.remove( "session" );
		Cookies.set( "session", JSON.stringify( session ), { expires: 14 } );
	}

	const getSession : any = () => {
		const sessionCookie = Cookies.get( "session" );
		// console.log( JSON.stringify(sessionCookie));

		if( !sessionCookie ){
			return initialDataStore;
		}
		else {
			// console.log( JSON.parse( sessionCookie ) );
			return JSON.parse( sessionCookie );
		}
	}

	return {
		setSession,
		getSession
	}
}

export const sessionService = createSessionService();