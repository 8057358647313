import React, { useState, useRef } from 'react';
import styled from "@emotion/styled";
import { useDataStoreContext } from "src/Store/dataStoreContext";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { userService } from 'src/Services/UserService';

const LoginPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 360px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 0.75rem #cacaca);
  border-radius: 5px;
`;

const LoginHeader = styled.div`
  height:30px;
  padding: 5px;
  background-color: #acacac;
  border-radius: 5px 5px 0 0;
  color: #ffffff;
  font-weight: bold;
`;

const LoginContent = styled.div`
  width:320px;
  position:absolute;
  left: 50%;
  transform: translate(-50%);
  padding:10px
`;

const LoginLabel = styled.h5`
   margin-bottom:5px;
`;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dataStore, dataStoreDispatch } = useDataStoreContext();

  const message = useRef<Messages>(null);

  const login = () => {
    if (username.length > 0 && password.length > 0) {
      userService.login( username, password )
      .then( (resp) => {
        if( resp.ok ){
          resp.json()
          .then( (fullResp ) => {
            dataStoreDispatch({
              kind:"USER_LOGIN_SUCCESS",
              payload: fullResp
            } );
          } );
        }
        else{
          message.current?.show( {severity: 'error', summary:'Invalid username or password', sticky: true, closable: false } );
        }
      } );
    }
  }

  return (
    <LoginPanel>
      
      <LoginHeader>
        User Login
      </LoginHeader>
      <Messages ref={message}></Messages>
      <LoginContent>
        <div>
          <LoginLabel>Username</LoginLabel>
          <InputText name="username" style={{width:300}} onChange={(e) => setUsername(e.target.value)} />
        </div>

        <div>
          <LoginLabel>Password</LoginLabel>
          <Password name="password" toggleMask={true} style={{width:300}} feedback={false} inputStyle={{width:300}} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div style={{marginTop: "1.67em"}}>
          <Button name="login" label="Login" onClick={login} style={{width:300}} />
        </div>
      </LoginContent>
    </LoginPanel>
  )
}

export default Login;