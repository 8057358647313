import React, {useState, useEffect, useRef} from 'react';
import styled from "@emotion/styled";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Menu } from 'primereact/menu';
import { uybService } from 'src/Services/ApiService';
import { Order } from 'src/Types/types';
import StatusDropDown from '../Common/StatusDropDown';
import { useDataStoreContext } from "src/Store/dataStoreContext";
import { stripSlashes, cleanString } from 'src/Util/stripslashes';
import { config } from 'src/Config/Config';
import ReactToPrint from 'react-to-print';
import 'src/Components/Orders/OrderTable.css';
import { userService } from 'src/Services/UserService';
import { saveAs } from 'file-saver';

const OrderMenu = styled.div`
  display: flex;
  flex-flow: row;
	height: 75px;
	border-bottom: 1px solid black;
  padding: 18px 10px 10px 10px;
  background: #d4d4d6;

  h1{
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 2em;
  }
`;

const OrderTitle = styled.div`
  flex: 50%;
`;

const OrderProfile = styled.div`
  flex: 50%;
  text-align: right;
  vertical-align: middle;
`;

const LoginName = styled.span`
  display: inline-block;
  height: 37px;
  padding: 10px 0 0 0;
  margin: 0px 10px 0 0;
`;

const OrderDisplay = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  /*background-color: rgb( 240, 240, 240 );*/

  div {
    margin-bottom: 10px;
  }
`;

const PrintDiv = styled.div`
  position: absolute;
  right: 30px;
`;

const statusToClass = [
  {className:'', label:''},
  {className:'new', label:'New'},
  {className:'in-progress', label:'In Progress'},
  {className:'completed', label:'Completed'},
  {className:'shipped', label:'Shipped'},
  {className:'waiting-for-payment', label:'Waiting for Payment'},
  {className:'waiting-for-information', label:'Waitinf for Information'}
]


const OrderTable = () => {
  const [loaded, setLoaded] = useState( false );
	const [orders, setOrders] = useState<Order[]>([]);
  const [expandedRows, setExpandedRows] = useState<any[]>();
  const [status, setStatus] = useState( 0 );
  const [statusFilterId, setStatusFilterId] = useState(0);
  const { dataStore, dataStoreDispatch } = useDataStoreContext();

  // reload order when the status has changed
  const [reload, setReload] = useState( false );
  const [removeId, setRemoveId] = useState(0);
  const [changedId, setChangedId] = useState( 0 );

  const dt = useRef<DataTable>(null);
  const userMenu = useRef<Menu>(null);

  // card refs
  const orderRefs = useRef<HTMLDivElement[]>([]);
  
	useEffect( () => {
    uybService.getOrders()
    .then( ( resp ) => {
      
      if( resp.length > 0 ) {
        resp.map( ( o, i ) => {
          const cleanStr = cleanString( o.data );
          // console.log( cleanStr );
          try{
            const obj = JSON.parse( cleanStr );
            o.dataObj = obj;
          }
          catch( e ){
            console.log( e );
          }
          
        } );
      }
      
      setOrders( resp );
      setLoaded( true );
    } );
	}, [] );

  useEffect( () => {
    if( reload === true ){
      // get changed order
      uybService.getOrder( changedId )
      .then( ( response ) => {
        // replace in array
        console.log( `replacing ${changedId} in array` );
        response.dataObj = JSON.parse( stripSlashes( response.data ) );
        const tmpOrders = [...orders];

        for( let x=0; x < tmpOrders.length; x++ ){
          if( tmpOrders[x].order_id === changedId ){
            tmpOrders[x] = response;
            break;
          }
        }

        setOrders( tmpOrders );
        setReload( false );
        setChangedId( 0 );
      } );
    }
  }, [reload] );

  useEffect( () => {
    if( removeId > 0 ){
      // replace in array
      console.log( `replacing ${changedId} in array` );
      const tmpOrders = [...orders];

      for( let x=0; x < tmpOrders.length; x++ ){
        if( tmpOrders[x].order_id === removeId ){
          tmpOrders.splice( x, 1 );
          break;
        }
      }

      setOrders( tmpOrders );
      setRemoveId( 0 );
    }
  }, [removeId] );

  const nameBodyTemplate = ( rowData: Order) => {
    const className = statusToClass[parseInt(rowData.order_status)].className;
    if( rowData.dataObj?.shippingAddress.name && rowData.dataObj?.shippingAddress.name.length > 0 ){
      return ( 
        <div className={className}>{rowData.dataObj?.shippingAddress.name}</div> 
      );
    }
    else {
      return ( 
        <div className={className}>{rowData.dataObj?.billingAddress.name}</div> 
      );
    }
  }

  const orderDateBodyTemplate = ( rowData: Order ) => {
    const className = statusToClass[parseInt(rowData.order_status)].className;
    return ( 
      <div className={className}>{rowData.order_date}</div> 
    );
  }

  const statusBodyTemplate = ( rowData: Order ) => {
    const className = statusToClass[parseInt(rowData.order_status)].className;
    return ( 
      <div className={className}>{rowData.order_status_name}</div> 
    );
  }

  const paymentStatusBodyTemplate = ( rowData: Order ) => {
    return ( 
      <div>{rowData.payment_status}</div> 
    );
  }

  const onRowExpand = () => {

  }

  const onRowCollapse = () => {

  }

  const onStatusChange = ( id: number, status: number ) => {
    uybService.setOrderStatus( id, status )
      .then((response) => {
        console.log( `success` );
        setChangedId( id );
        setReload( true );
       } )
      .catch((error) => console.log( `rejected` ) )
  }

  const onStatusFilterChange = (id: number, status: number) => {
    let filterLabel = null;
    
    if( status !== undefined ){
      filterLabel = statusToClass[status].label;
      console.log( `looking for ${filterLabel}` );
    }

    if( dt.current ) dt.current.filter( filterLabel, 'order_status_name', 'equals' );
    setStatusFilterId( status );
  }

  const onTrashClick = ( event: React.MouseEvent, id: number ) => {
      confirmPopup({
          target: event.currentTarget as HTMLElement,
          message: 'Are you sure you want to delete this record?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => {
            uybService.deleteOrder( id )
            .then( (resp)=>{
              setRemoveId( id );
            });
          },
          reject: () => console.log( "wont delete" )
      });
  }

  const saveFileClick = ( fileName: string, url: string ) => {
    // fetch( url )
    //   .then( res => res.blob() )
    //   .then( (blob) => {
    //     saveAs( blob, fileName );
    //   } );
    saveAs( url, fileName );
  }

  const onProfileClick = () => {

  }

  const onLogoutClick = () => {
    userService.logout()
    .then( (resp) => {
      dataStoreDispatch({
        kind:"USER_LOGOUT",
        payload: true
      } );
    } );
  }

  const userMenuItems = [
    {
      label: 'Profile', 
      icon: 'pi pi-fw pi-info-circle',
      command: ()=>{
        onProfileClick();
      }
    },
    {
      label: 'Log Out', 
      icon: 'pi pi-fw pi-sign-out',
      command: ()=>{
        onLogoutClick();
      }
    }
  ];
  // console.log( dataStore.user );
  
  const rowExpansionTemplate = ( data: any ) => {
    console.log( data );
    if( !data.dataObj || ( ! data.dataObj.billingAddress || !data.dataObj.billingAddress.name || data.dataObj.billingAddress.name.length <= 0 ) && 
      (!data.dataObj.shippingAddress.name ||
        data.dataObj.shippingAddress.name.length <= 0 ) ) 
        return ( 
          <OrderDisplay>
            <div className="order-half-cell">There is no data associated to this record.</div>
            <Button icon="pi pi-trash" className="p-button-danger" style={{marginLeft: "2px"}} onClick={(e)=>onTrashClick(e, data.order_id)} />
          </OrderDisplay>
        );

    let names = data.dataObj.tagName;
    let quantLTNames = false;

    if( data.dataObj.nameList ){
      names = data.dataObj.nameListContent;
      console.log( names );
      if( names && Array.isArray( names ) ){
        quantLTNames = names.length > data.dataObj.tagOptions.quantity;
      }
      else{
        quantLTNames = (names.split( ",").length > data.dataObj.tagOptions.quantity);
      }

      // data.dataObj.nameListContent.forEach( (name:string) =>{
      //   names += name + ", ";
      // } );
      // names = names.substring( 0, names.length - 2 );

      // if( data.dataObj.nameListContent.length > data.dataObj.tagOptions.quantity ){
      //   quantLTNames = true;
      // }
    }

    let shipping = data.dataObj.shippingAddress;

    return ( 
      <OrderDisplay ref={(el) => {if( el ) (orderRefs.current[data.order_id] = el)}} >
        <div className="order-half-cell"><b>Order ID: </b>{data.order_id}</div>
        <div className="order-half-cell"><b>Order Date: </b>{data.order_date}</div>

        <div className="order-half-cell"></div>
        <div className="order-quarter-cell"><b>Subtotal: </b></div>
        <div className="order-eighth-cell right">{data.dataObj.orderPrice.subTotal}</div>
        <div className="order-eighth-cell"></div>
        
        <div className="order-half-cell"></div>
        <div className="order-quarter-cell"><b>Tax: </b></div>
        <div className="order-eighth-cell right">{data.dataObj.orderPrice.tax}</div>
        <div className="order-eighth-cell"></div>

        <div className="order-half-cell"></div>
        <div className="order-quarter-cell"><b>Shipping: </b></div>
        <div className="order-eighth-cell right">{data.dataObj.orderPrice.shipping}</div>
        <div className="order-eighth-cell"></div>

        <div className="order-half-cell"></div>
        <div className="order-quarter-cell"><b>Total: </b></div>
        <div className="order-eighth-cell right">{data.dataObj.orderPrice.total}</div>
        <div className="order-eighth-cell"></div>
        
        <div className="order-full-cell">
          <b>Order Status: </b>
          <StatusDropDown orderId={data.order_id} value={data.order_status} onChange={onStatusChange} toolTip="Change the status of this order" />
        </div>

        <PrintDiv>
          <ReactToPrint
            documentTitle="Hard Hat Tags Order"
            trigger={() => <Button icon="pi pi-print" />}
            content={() => orderRefs.current[data.order_id]}
          />
          <Button icon="pi pi-trash" className="p-button-danger" style={{marginLeft: "2px"}} onClick={(e)=>onTrashClick(e, data.order_id)} />
        </PrintDiv>
        
        <div className="order-third-cell">
          <div className="info-block">
            <h3>Tag Information</h3>
            <div><b>Name{data.dataObj.nameList?"s":""}: </b>{names}</div>
            <div><b>Font: </b>{data.dataObj.tagOptions.font}</div>
            <div><b>Bold: </b>{data.dataObj.tagOptions.bold?"Yes":"No"}</div>
            <div><b>Color Combo: </b>{data.dataObj.tagOptions.colorCombo}</div>
            <div><b>Border: </b>{data.dataObj.tagOptions.border?"Yes":"No"}</div>
            <div><b>Include Adhesive: </b>{data.dataObj.tagOptions.includeAdhesive?"Yes":"No"}</div>
            <div><b>Quantity: </b>{data.dataObj.tagOptions.quantity} {quantLTNames&&<div><span style={{color:"red"}}>NOTICE:</span> Quantity ordered is less than names provided</div>}</div>
            <div dangerouslySetInnerHTML={{__html:'<b>Comments: </b><br />' + data.dataObj.tagOptions.comments.replace( /\r\n/g, "<br />" )}} />
            {data.dataObj.imgFile &&
            <div><b>Image: </b> 
            <br />
              {data.dataObj.img && <img src={`${config.imgUrl}filetmp/${data.dataObj.imgFile}`} width={200} />}
              <div><Button label="Download Image" onClick={()=>saveFileClick( data.dataObj.imgFile, `${config.imgUrl}filetmp/${data.dataObj.imgFile}` ) } /></div>
            </div>
            }
          </div>
        </div>

        <div className="order-third-cell">
          <div className="info-block">
            <h3>Shipping Information</h3>
            <div><b>Name: </b>{shipping.name}</div>
            <div><b>Email: </b>{shipping.email}</div>
            <div><b>Street: </b>{shipping.street1}</div>
            <div><b>Street2: </b>{shipping.street2}</div>
            <div><b>City: </b>{shipping.city}</div>
            <div><b>State: </b>{shipping.state}</div>
            <div><b>Zip: </b>{shipping.zip}</div>
            <div><b>Phone: </b>{shipping.phone}</div>
          </div>
        </div>
        <div className="order-full-cell"><b>User Agent: </b>{data.user_agent}</div>
      </OrderDisplay> 
    );
  }

  const statusFilter = <StatusDropDown orderId={0} value={statusFilterId} onChange={onStatusFilterChange} showClear placeholder="Filter Status" />

	return(
    !loaded?
    <div></div>
    :
		<div>
      <Menu model={userMenuItems} popup ref={userMenu} />

			<OrderMenu >
        <OrderTitle><h1>Hard Hat Tags Order System</h1></OrderTitle>
        <OrderProfile>  
          <LoginName>{dataStore.user.first_name} {dataStore.user.last_name}</LoginName>
          <Button 
            icon="pi pi-user" 
            className="p-button-primary p-button-rounded" 
            tooltip="Logout" 
            onClick={(e) => {if( userMenu.current ) userMenu.current.toggle(e);}} /></OrderProfile>
			</OrderMenu>
			<DataTable 
        ref={dt}
        value={orders} 
        stripedRows 
        expandedRows={expandedRows} 
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="order_id"
        paginator
        rows={20}
        emptyMessage="No Orders Found">
        <Column expander style={{ width: '3em' }} />
				<Column style={{width: '10%'}} field="order_id" header="ID"></Column>
        <Column style={{width: '30%'}} field="dataObj.shippingAddress.name" header="Name" body={nameBodyTemplate} filter filterPlaceholder="Search for Name" filterMatchMode="contains"></Column>
				<Column style={{width: '20%'}} field="order_date" header="Order Date" body={orderDateBodyTemplate}></Column>
				<Column style={{width: '20%'}} field="order_status_name" header="Order status" body={statusBodyTemplate} filter filterElement={statusFilter}></Column>
			</DataTable>
		</div>
	);

}

export default OrderTable;