import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { uybService } from 'src/Services/ApiService';

type StatusOption = {
  label: string;
  value: number;
};

type DropDownProps = {
  orderId: number;
  value: number;
  placeholder?: string;
  showClear?: boolean;
  toolTip?: string;
  onChange?: (id: number, status: number) => void;
}

const StatusDropDown = (props: DropDownProps) => {
  const {orderId, value, placeholder, showClear, toolTip, onChange} = props;
  const [selected, setSelected] = useState( value );
  const [options, setOptions] = useState<StatusOption[]>([])

  useEffect( () => {
    const opts: StatusOption[] = [];
    uybService.getAllStatus()
    .then( (resp) => {
      resp.map( (opt) => {
        const {order_status_id, order_status_name} = opt;
        opts.push( {label:order_status_name, value: order_status_id} );
      } );

      setOptions( opts );
    } );
  }, [] );

  const onDropDownChange = ( value: number ) => {
    setSelected( value );
    if( onChange ) onChange( orderId, value );
  }

  return(
    <Dropdown
      style={{width: "250px", height: "35px"}}
      value={selected}
      options={options}
      showClear={showClear}
      placeholder={placeholder}
      tooltip={toolTip}
      onChange={ (e) => onDropDownChange(e.value) }
    />
  )
}

export default StatusDropDown;