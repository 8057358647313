import {config} from "src/Config/Config"
import { Order, Status } from 'src/Types/types';

const createUYBService = () => {
  const getOrder = async( id: number ): Promise<Order> => {
		const options: RequestInit = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include'
		};

		const resp = await fetch( `${config.apiUrl}/orders/id:${id}`, options );
    if( !resp.ok ){
      throw Error( resp.statusText );
    }
    const json = await resp.json();
		return json;
	}

	const getOrders = async(): Promise<Order[]> => {
		const options: RequestInit = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include'
		};

		const resp = await fetch(config.apiUrl + "/orders", options );
    if( !resp.ok ){
      throw Error( resp.statusText );
    }
    const json = await resp.json();
		return json;
	}

  const setOrderStatus = async(id: number, status: number): Promise<Response> => {
    const options: RequestInit = {
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
      body: JSON.stringify( {id: id, status: status} )
		};

    const resp = await fetch(config.apiUrl + "/orders", options  );
    if( !resp.ok ){
      throw Error( resp.statusText );
    }
    const json = await resp.json();
		return json;
  }

  const deleteOrder = async( id: number ): Promise<Response> => {
    const options: RequestInit = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
      body: JSON.stringify( {id: id} )
		};

		const resp = await fetch(config.apiUrl + "/orders", options );
    if( resp.status !== 204 ){
      throw Error( resp.statusText );
    }

		return resp;
  }

  const getAllStatus = async(): Promise<Status[]> => {
		const options: RequestInit = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include'
		};

		const resp = await fetch(config.apiUrl + "/status", options );
    if( !resp.ok ){
      throw Error( resp.statusText );
    }
    const json = await resp.json();
		return json;
	}

  return{
    getOrder,
		getOrders,
    setOrderStatus,
    deleteOrder,
    getAllStatus
  };
}

export const uybService = createUYBService();