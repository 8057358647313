import React from 'react';
import { useDataStoreContext } from "src/Store/dataStoreContext";
import Login from 'src/Components/Login/Login';
import OrderTable from './OrderTable';

const Orders = () => {
  const { dataStore, dataStoreDispatch } = useDataStoreContext();

  return(
    <div>
      {dataStore.passedAuth ? <OrderTable /> : <Login />}
    </div>
  )
}

export default Orders;