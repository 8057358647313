import {config} from "src/Config/Config"
import { Order, Status, User } from 'src/Types/types';

const createUserService = () => {
  const login = async( userName: String, password: String ): Promise<Response> => {
    const options: RequestInit = {
      method: 'POST',
      body:JSON.stringify({username:userName, password:password}),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    };

    const resp = await fetch(config.apiUrl + "/user", options);
    return resp;
  }

  const logout = async(): Promise<Response> => {
    const options: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    };

    const resp = await fetch(config.apiUrl + "/user", options);
    return resp.json();
  }

  const getProfile = async(): Promise<Response> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    };

    const resp = await fetch(config.apiUrl + "/user", options);
    return resp.json();
  }

  return{
    login,
    logout,
    getProfile
  };
}

export const userService = createUserService();